<template>
  <div class="d-flex flex-column py-4">
    <v-row>
      <v-col class="col-12" v-if="loading">
        <v-card>
          <v-card-text class="wait">
            <h2>Loading...</h2>
            <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" v-else-if="found === false">
        <v-card>
          <v-card-text class="wait">
            <h2>No record was found.</h2>
            <v-icon size="80" color="primary"> mdi-close-octagon-outline </v-icon>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12" v-else>
        <div class="headet-top">
          <h2>Report Bug Details</h2>
          <v-btn color="indigo" @click="showDialog">Discard </v-btn>
        </div>

        <v-card>
          <v-card-text>
            <v-form ref="form" class="multi-col-validation">
              <div class="container-main">
                <div class="row-line"></div>
                <div class="row-line">
                  <span class="text"><b>Email:</b></span>
                  <span>{{ email }}</span>
                </div>
                <div class="row-line">
                  <span class="text"><b>URL: </b></span>
                  <a :href="data.location" target="_blank">{{ data.location }}</a>
                </div>
                <div class="row-line">
                  <span class="text"><b>Details:</b></span>
                  <span>{{ data.details }}</span>
                </div>
                <div class="row-line">
                  <span class="text"><b>Device Used:</b></span>
                  <span>{{ data.device }}</span>
                </div>
                <div class="row-line">
                  <span class="text"><b>Browser:</b></span>
                  <span>{{ data.browser }}</span>
                </div>
                <div class="row-line">
                  <span class="text"><b>Operating System:</b></span>
                  <span>{{ data.os }}</span>
                </div>
              </div>

              <v-row style="margin-top: 10px">
                <v-col sm="6" md="4" lg="3" cols="12">
                  <div class="row-option">
                    <span class="text"><b>Status:</b></span>
                    <v-select
                      v-model="data.status"
                      :items="status_list"
                      label=""
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                    />
                  </div>
                </v-col>
                <v-col sm="6" md="4" lg="3" cols="12">
                  <div class="row-option">
                    <span class="text"><b>Priority:</b></span>
                    <v-select
                      v-model="data.priority"
                      :items="priorities"
                      label=""
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                    />
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn color="primary" class="btn-submit" @click="save" :disabled="saving">
                    <span v-if="saving === false">Save</span>
                    <span v-else>
                      <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
                      <span>Saving...</span>
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col-12" v-if="found && data && loading === false">
        <div class="headet-top">
          <h2>Attachments</h2>
        </div>

        <v-card class="mt-2" v-if="data.files">
          <v-card-text v-if="data.files.length > 0">
            <div class="gallery">
              <div v-for="(file, index) in data.files" :key="index">
                <img class="image" :src="file.url" :alt="file.filename" @click="setImage(index)" />
              </div>
            </div>

            <vue-easy-lightbox
              escDisabled
              moveDisabled
              :visible="visible"
              :imgs="images"
              :index="index"
              @hide="handleHide"
            ></vue-easy-lightbox>
          </v-card-text>
          <v-card-text v-else>
            <div>
              <span class="center">No files were found.</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <dialog-discard
          :show.sync="dialog_discard"
          :item="data"
          title="Are you sure to discard this bug report?"
          description="This process is irreversible, and can no longer be recovered, would you like to continue?"
          :action="discardRequest"
        ></dialog-discard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'
//components
import VueEasyLightbox from 'vue-easy-lightbox'
import { mdiDelete, mdiCog } from '@mdi/js'
import DialogDiscard from './components/DialogDiscard.vue'

export default {
  components: {
    VueEasyLightbox,
    DialogDiscard,
  },
  data() {
    return {
      loading: false,
      saving: false,
      errors: null,
      found: false,
      email: '',
      fullname: '',
      data: {},
      status_list: [
        { name: 'Pending', value: 'pending' },
        { name: 'In Progress', value: 'in-progress' },
        { name: 'Solved', value: 'solved' },
        { name: 'Done', value: 'done' },
      ],
      priorities: [
        { name: 'High', value: 'high' },
        { name: 'Medium', value: 'medium' },
        { name: 'low', value: 'low' },
      ],

      visible: false,
      index: 0,
      images: [],
      icons: {
        mdiDelete,
        mdiCog,
      },

      dialog_discard: false,
    }
  },
  computed: {
    darkTheme() {
      return this.$vuetify.theme.isDark
    },
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    ...mapActions({
      getReportBug: 'report_bug/getReportBug',
      setSnackbar: 'snackbar/set',
      update: 'report_bug/update',
      discard: 'report_bug/discard',
    }),
    async getDetails() {
      this.loading = true
      await this.getReportBug(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
            this.found = true

            if (resp.data.user) {
              if (resp.data.user.profile) {
                if (resp.data.user.profile.first_name) {
                  this.fullname = resp.data.user.profile.first_name + ' '
                }
                if (resp.data.user.profile.last_name) {
                  this.fullname = resp.data.user.profile.last_name
                }
              }
              this.email = resp.data.user.email
            }

            this.images = resp.data.files.map(file => {
              return {
                title: file.filename,
                src: file.url,
              }
            })
          }
        })
        .catch(error => {
          this.found = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.saving = true
        await this.update(this.data)
          .then(resp => {
            if (resp && resp.data) {
              if (resp.data.status === 'ok') {
                this.setSnackbar({
                  status: true,
                  text: 'Successfully saved.',
                  color: 'primary',
                })
                this.errors = null
              }
            }
          })
          .catch(error => {
            this.setSnackbar({
              status: true,
              text: 'The request could not be submitted.',
              color: 'error',
            })

            if (error.response.data && error.response.data.status === 'error') {
              this.errors = error.response.data.errors
            } else {
              this.errors = null
            }
          })
          .finally(() => {
            this.saving = false
          })
      }
    },
    goToPath(path) {
      this.$router.push({ path })
    },

    setImage(index) {
      this.visible = true
      this.index = index
    },
    handleHide() {
      this.visible = false
    },
    showDialog() {
      this.dialog_discard = true
    },
    async discardRequest() {
      this.loading = true
      await this.discard(this.data)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status === 'ok') {
              this.setSnackbar({
                status: true,
                text: 'The application has been discarded.',
                color: 'primary',
              })
              this.errors = null
              this.goToPath('/report-bug-list')
            }
          }
        })
        .catch(error => {
          this.setSnackbar({
            status: true,
            text: 'The request could not be submitted.',
            color: 'error',
          })

          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.container-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headet-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.headet-top .header-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
}
.wait {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.text {
  font-size: 16px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.row-line {
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: flex-start;
  gap: 10px;
}
.container-line {
  margin-top: 10px;
  width: 100%;
  padding: 20px 20px;
  /* border: 1px solid #595573; */
  border-radius: 10px;
}
.container-line.dark {
  border: 1px solid #595573;
}
.container-line.light {
  border: 1px solid rgba(68, 68, 68, 0.28);
}

.gallery {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}
.image {
  width: 100%;
  cursor: pointer;
}

@media (max-width: 1367px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .container-main {
    padding: 0px;
  }
  .headet-top {
    flex-direction: column;
  }
  .headet-top .header-actions {
    flex-direction: column;
  }
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 585px) {
  .gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .row-option,
  .row-line {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
</style>
